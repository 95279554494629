import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import Slack from '../../assets/images/integrations/slack.svg'
import Teams from '../../assets/images/integrations/teams.png'
import MetaImage from '../../assets/images/product/working-agreements/working-agreements-meta.png'
import tag from '../../assets/images/tags/working-agreements-tag.svg'
import Layout from '../../components/Layout'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import FooterBlock from '../../components/blocks/FooterBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import Box from '../../components/primitives/Box'
import Row from '../../components/primitives/Row'
import Stack from '../../components/primitives/Stack'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/working-agreements/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/working-agreements/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/product/working-agreements/alerts.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: {
        eq: "images/product/working-agreements/team-overview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    reviewTime: file(
      relativePath: { eq: "images/product/working-agreements/review-time.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    dailyDigest: file(
      relativePath: {
        eq: "images/product/working-agreements/slack-daily-digest.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement3: file(
      relativePath: { eq: "images/product/overview/working-agreement.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const WorkingAgreements = () => {
  const data = useStaticQuery(query)
  return (
    <Layout
      title="Working agreements"
      variant="dark"
      isNew
      description="Working agreements put engineering teams in charge of their continuous improvement. They allow teams to set and track numeric targets and adopt industry best practices."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Put continuous improvement&nbsp;on autopilot</>}
        content="Working agreements give software teams the feedback loops they need to get a little bit better every day."
        primaryButton={{ text: 'Get a demo', href: '/demo/' }}
        secondaryButton={{ text: 'Start free trial', href: '/signup/' }}
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        paddingBottom={responsiveScale(64)}
        heading="Metrics alone don’t help teams to improve"
        content="Working agreements put teams in control of how they want to work together. Whether you’re looking to set numeric targets or build healthy habits, there’s a working agreement for that."
        link={{
          title: 'Read our practical guide to working agreements',
          href: '/blog/agile-team-working-agreements',
        }}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Adopt proven ways of working"
            text="Work with your team to set relevant and achievable targets."
            image={getImage(data.workingAgreement1)}
          />,
          <Blurb
            key="2"
            title="Enable automated reminders"
            text="Get real-time alerts in Slack to stay on top of your team's agreements."
            image={getImage(data.workingAgreement2)}
          />,
          <Blurb
            key="3"
            title="Track progress over time"
            text="Avoid slipping and celebrate progress with frequent recaps."
            image={getImage(data.workingAgreement3)}
          />,
        ]}
      />
      <LeadBlock
        heading={<>Adopt best practices from high-performing teams</>}
        content={
          <>
            Work in progress, cross-team collaboration, review time, code
            quality —&nbsp;choose the areas you’d like to improve, and Swarmia
            will help you get there.
          </>
        }
        alt="Working agreements"
        image={getImage(data.lead)}
      />
      <LeadBlock
        title={
          <Box>
            <Box.img loading="lazy" height={48} src={Slack} marginRight={32} />
            <Box.img loading="lazy" height={48} src={Teams} />
          </Box>
        }
        heading="Automate feedback loops"
        content={
          <>
            Get personal and team reminders to unblock the flow and
            stay&nbsp;on&nbsp;top&nbsp;of&nbsp;your working agreements.
          </>
        }
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(92)}
        columns={[
          <Blurb
            key="1"
            title="Personal nudges"
            text="Speed up code reviews and unblock teammates with personal notifications."
            image={getImage(data.reviewTime)}
            isLarge
          />,
          <Blurb
            key="2"
            title="Team digests"
            text="Bring visibility into ongoing work and celebrate completed stories and epics."
            image={getImage(data.dailyDigest)}
            isLarge
          />,
        ]}
      />
      <MosaicBlock
        title="Designed to help teams take action"
        content={
          <Stack space={24}>
            <Stack space={16}>
              <Box>
                React to working agreement exceptions before they turn into
                serious bottlenecks.
              </Box>
              <Box>
                Using working agreements together with Swarmia’s Slack
                notifications has a proven correlation with faster code reviews
                and lower cycle times.*
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black500">
                Swarmia’s own research based on data
                from&nbsp;500+&nbsp;engineering&nbsp;teams.
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Measure impact across the whole organization"
        content="Get a quick overview of how different teams are doing and which working agreements they’ve adopted."
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
      />
      <QuoteBlock person="carsten2" />
      <FooterBlock productPages={['investmentBalance', 'insights']} />
    </Layout>
  )
}

export default WorkingAgreements
